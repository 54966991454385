import { useState } from "react";
import { Modal, Button} from 'react-bootstrap';

const ModalCard = (props) => {

    const [smShow, setSmShow] = useState(false);
    const {card} = props;

    return (
        <>
            <Button onClick={()=> setSmShow(true)} className="border-0" variant="light"> 
                <strong>Saiba + </strong> 
            </Button>
            
            <Modal
            show={smShow}
            dialogClassName="modal-30w"
            onHide={()=>setSmShow(false)}
            aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">
                   {card.title}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                    {card.description}
                    </p>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalCard;