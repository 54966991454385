import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Modal, Button } from 'react-bootstrap';

import radarImg from '../../assets/radar.jpeg';
import metodoImg from '../../assets/metodo-img.png';

import { TbHandClick } from 'react-icons/tb';
import { IconContext } from 'react-icons';

import metodologiaList from '../../data/metodologia.json';

import './metodologia.css'; 

const Metodologia = () => {

  const [smShow, setSmShow] = useState(false);
  const [modalTitle, setModalTitle] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);


    const getModal = (state, section) => {
        setSmShow(state);
       const sectionTitle =  metodologiaList[section];
       setModalTitle(sectionTitle);
    }

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return (
      <div className="full-page-section-metod">
       
            { smShow &&
              <Modal
                show={smShow}
                dialogClassName="modal-30w"
                onHide={()=>setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
                >
                  <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-sm">
                     {modalTitle.title}
                  </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <p>
                      {modalTitle.description}
                      </p>
                  </Modal.Body>
               </Modal>
            }
              <Container fluid >
                <h2 className='titulo-metodo'>{metodologiaList.title}</h2>

                    <Row style={{margin:'1% 5% 5% 5%'}}>
                      <Col sm={6} className="d-flex justify-content-center align-items-center">
                          <div style={{width:'100%', padding:'20%'}}>
                            <pre className='descricao-text'>
                                  {metodologiaList.descrição}
                            </pre>
                          </div>
                      </Col>
                      <Col sm={6} className="d-flex justify-content-center align-items-center">
                          <Image 
                            src={metodoImg} 
                            alt="MetodoIMG"
                            />
                      </Col>
                    </Row>

                    <Row style={{marginBottom:'5%'}}>
                      <Col xs={12} className="position-relative">
                        <Image className='img-radar' src={radarImg} />
                        <Button className="position-absolute top-10 start-0 translate-middle button-relacio" variant="transparent"
                          onClick={()=>getModal(true, "Relacionamento")}>
                            <strong className="title-meto">Relacionamento</strong> 
                            <div> 
                              <IconContext.Provider value={{ size: isMobile ? '1.3em' : '2em', color:'#ffeb3c '}}>
                                      <TbHandClick className="icon-metodologia" />
                              </IconContext.Provider>
                            </div>
                        </Button>

                        <Button className="position-absolute top-0 start-50 translate-middle button-desenvol" variant="transparent"
                         onClick={()=>getModal(true, "Desenvolvimento")}>
                          <strong className="title-meto">Desenvolvimento</strong> 
                          <div> 
                            <IconContext.Provider value={{ color: '#fec107 ', size: isMobile ? '1.3em' : '2em',}}>
                                <TbHandClick className="icon-metodologia" />
                            </IconContext.Provider>
                          </div>
                        </Button>
                        <Button className="position-absolute top-0 start-50 translate-middle button-result" variant="transparent"
                         onClick={()=>getModal(true, "Resultado")}>
                         <strong className="title-meto">Resultado</strong> 
                         <div> 
                            <IconContext.Provider value={{ color: '#ea1e63', size: isMobile ? '1.3em' : '2em',}}>
                                <TbHandClick className="icon-metodologia" />
                            </IconContext.Provider>
                          </div>
                        </Button>
                        <Button className="position-absolute top-50 start-0 translate-middle button-acao" variant="transparent"
                         onClick={()=>getModal(true, "Ação")}>
                          <strong className="title-meto">Ação</strong>
                          <div> 
                            <IconContext.Provider value={{ color: '#fe5722', size: isMobile ? '1.3em' : '2em',}}>
                                <TbHandClick className="icon-metodologia" />
                            </IconContext.Provider>
                          </div>
                        </Button>
                        <Button className="position-absolute top-50 start-0 translate-middle button-analise" variant="transparent"
                         onClick={()=>getModal(true, "Análise")}>
                          <strong className="title-meto">Analise</strong>
                          <div>
                            <IconContext.Provider value={{ color: '#fec107', size: isMobile ? '1.3em' : '2em',}}>
                                <TbHandClick className="icon-metodologia" />
                            </IconContext.Provider>
                          </div>
                        </Button>
                      </Col>
                    </Row>
              </Container>
      
      </div>
    );
}

export default Metodologia;