import { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import imgXadrez from '../../assets/perfil-xadrez.jpeg';

import links from '../../data/links.json';
import sobre from '../../data/sobre.json';

import './sobre.css'; 

const Sobre = () => {

    const [isVisible, setIsVisible] = useState(false);
    const animatedComponentRef = useRef(null);
  
    useEffect(() => {
      const handleScroll = () => {
        if (
          window.pageYOffset + window.innerHeight >
          animatedComponentRef.current.offsetTop
        ) {
          setIsVisible(true);
        }
      };
  
      window.addEventListener("scroll", handleScroll); 
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);

  return (
     <div className="full-page-section-sobre">

            <Container className="h-100" style={{paddingTop:'2%'}}>
                <Row>
                    <Col className="d-flex align-items-center" ref={animatedComponentRef}>
                        <img className={`img ${isVisible ? "show" : ""} img-sobre`} src={imgXadrez} alt="Imagem descritiva"/>
                    </Col>

                    <Col className="d-flex align-items-center" ref={animatedComponentRef} md={6}>
                                <div 
                                    style={{
                                        textAlign:'justify',
                                    }} 
                                    className={`text ${isVisible ? "show" : ""}`}>
                                    <div style={{textAlign:'center'}}>
                                      <h2 style={{
                                        textTransform:'uppercase', 
                                        fontFamily:'Poppins',
                                        fontWeight:'bold'
                                        }}>
                                          Quem sou?
                                      </h2>
                                    </div>
                                    <pre className='descricao-sobre'>
                                      {sobre.description}
                                    </pre>
                                     <div style={{textAlign:'center'}}>
                                        <Button className='button-sobre' size="lg" variant="warning">
                                          <a href={links.whatsapp} target="_blank" rel="noopener noreferrer" style={{textDecoration:'none', color:'#000'}}> 
                                            Entre em contato
                                          </a>
                                         </Button>{' '} 
                                    </div> 
                                    
                                </div> 
                        </Col>
                </Row>
            </Container>
        </div>
  );
}

export default Sobre;