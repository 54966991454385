import {FIREBASE_API_KEY, FIREBASE_AUTHDOMAIN, FIREBASE_PROJECT_ID, FIREBASE_STORAGE_BUCKET, 
    FIREBASE_MESSAGIN_SENDER_ID,FIREBASE_APP_ID,
    FIREBASE_MEASUREMENT_ID} from '../../../settings/firebase_settings';

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTHDOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGIN_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID
};

const firebaseApp = initializeApp(firebaseConfig);
const fireStoreDB = getFirestore(firebaseApp);

export {fireStoreDB};