import { useState } from 'react';
import { Container, Row, Col,Image, Form, Button, Spinner, Tooltip, OverlayTrigger} from 'react-bootstrap';
import { IconContext } from 'react-icons';

import { fireStoreDB } from '../../data/services/firebase/FirebaseService';
import {collection, addDoc} from 'firebase/firestore';

import logoContato from '../../assets/Contato/logo-contato.png';
import instagram from '../../assets/Contato/instagram.png';
import whatsapp from '../../assets/Contato/whatsapp.png';
import emailIcon from '../../assets/Contato/email.png';

import links from '../../data/links.json';

import './contato.css';

const Contato = () => {

    const COLECTION = process.env.REACT_APP_FIREBASE_COLECTION_CONTATO;
    const email = process.env.REACT_APP_EMAIL_CONTATO;

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
      });

    const [copied, setCopied] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleCopyEmail = () => {
        navigator.clipboard.writeText(email);
        setCopied(true);

        setTimeout(() => {
            setCopied(false);
        }, 5000);
    };
    
    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async e => {
        e.preventDefault();

        if(formData.name && formData.email){

            setIsLoading(true);

            await addDoc(collection(fireStoreDB, COLECTION),{
                name: formData.name,
                email: formData.email,
                mensagem: formData.message
            }).then(()=>{
                setFormData({
                    name: '',
                    email: '',
                    message: ''
                });
                setIsLoading(false);
            }).catch((error)=>{
                console.log(error);
                setIsLoading(false);
            });
        }
    };


    return (
    <footer className="page-footer font-small blue pt-4">

        <Container className="container-fluid text-center text-md-left contato-container">
            <Row className="col-contatos">

                <Col className="contatos-descri">
                    <Image  src={logoContato} fluid/>
                </Col>

                <Col>
                    <h2 className="text-center mb-4" style={{fontWeight:'bold', paddingTop:'20px'}}>Entre em contato</h2>
                    <p>Preencha o formulário abaixo, e comece a cuidar da saúde financeira da sua empresa como ela merece.</p>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formBasicName" className="mb-3">
                            <Form.Control required  placeholder='Nome' type="text" name="name" value={formData.name} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group controlId="formBasicEmail" className="mb-3">
                            <Form.Control required  placeholder='E-mail' type="email" name="email" value={formData.email} onChange={handleChange} />
                        </Form.Group>

                        <Form.Group controlId="formBasicMessage" className="mb-3">
                            <Form.Control placeholder='Escreva aqui sua mensagem' as="textarea" rows={3} name="message" value={formData.message} onChange={handleChange} />
                        </Form.Group>

                        <Button variant="dark" disabled={isLoading} type="submit" className="w-100 mt-3">
                            {isLoading ? <Spinner animation="border" size="large" /> :<strong>Enviar</strong> }  
                        </Button>
                    </Form>
                    
                    <div style={{marginTop:'8%'}}>
                        <h4><strong>Siga-nos</strong></h4>
                    </div>
                    <div className="siga-nos">
                  
                                <div className='social-icons'>
                                    <a href={links.whatsapp} target="_blank" rel="noopener noreferrer">
                                        <IconContext.Provider value={{ color: 'green', size: '3em' }}>
                                            <Image src={whatsapp} className="icon-contato" />
                                        </IconContext.Provider>
                                    </a>
                                </div>

                                <div className='social-icons'>
                                    <a href={links.instagram} target="_blank" rel="noopener noreferrer">
                                        <IconContext.Provider value={{ color: '#DD2A7B', size: '3em' }}>
                                            <Image src={instagram} className="icon-contato" />
                                        </IconContext.Provider>
                                    </a>
                                </div> 

                                <div className='social-icons'>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="copiar-tooltip">{copied ? 'Copiado!' : 'Copiar'}</Tooltip>}>
                                    <Button variant="none" style={{padding:'0px', margin:'0px', border:'0px'}} onClick={handleCopyEmail}>
                                        <Image src={emailIcon} className="icon-contato" alt="Copiar"/>                 
                                    </Button>  
                                </OverlayTrigger>
                                </div>
                    </div>
                </Col>
            </Row>
        </Container>

        <div style={{
            backgroundColor:'#101920',
            justifyContent:'center',
            alignItems:'center',
            textAlign:'center',
            height:'30px',
            
            }}>
            <p style={{paddingTop:'3px', fontWeight:'bold', color:'white'}}>© 2023 Copyright: Karol Rios</p>
        </div>

    </footer>
    );
}

export default Contato;