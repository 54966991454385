import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { FaWhatsapp } from 'react-icons/fa';

import links from '../../data/links.json'

import './styles.css';

function WhatsappButton() {
  const [flashing, setFlashing] = useState(false);

  const handleFlash = () => {
    setFlashing(true);
    setTimeout(() => setFlashing(false), 2000);
  };

  const springProps = useSpring({
    right: flashing ? '-10px' : '-80px',
    backgroundColor: flashing ? '#25d366' : '#25d366',
    boxShadow: flashing ? '0px 0px 20px #25d366' : 'none',
    config: { tension: 200, friction: 20 },
  });

  return (
    <OverlayTrigger
      placement="left"
      overlay={
        <Tooltip id="whatsapp-tooltip">
          WhatsApp
        </Tooltip>
      }
    >

     <div className="whatsapp-button">
        <a href={links.whatsapp} target="_blank" rel="noopener noreferrer">
            <animated.span style={springProps}>
                <FaWhatsapp
                    size={40}
                    className="whatsapp-icon"
                    onClick={handleFlash}
                />
                </animated.span>
        </a>
    </div>   
        
    </OverlayTrigger>
  );
}


export default WhatsappButton;
