import React from 'react';

function IconImage(props) {
  const url = props.url;
  const size = props.size || 50;
  const estilo = {
    width: `${size}px`,
    height: `${size}px`,
  };

  return (
    <img
      src={url}
      alt="ícone"
      className={props.className}
      style={{ ...estilo, ...props.style }}
    />
  );
}

export default IconImage;
