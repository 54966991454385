import { Container, Row, Col, Card } from 'react-bootstrap';

import IconImage from '../../components/IconImage';
import diagnostico from '../../assets/Solucoes/gestao.png';
import gestão_fina from '../../assets/Solucoes/risco.png';
import orcamentaria from '../../assets/Solucoes/custo.png';
import implantacao from '../../assets/Solucoes/lampada2.png';
import processos_dados from '../../assets/Solucoes/processamento-de-dados.png';
import processos_diagnosticos from '../../assets/Solucoes/processo.png';
import ModalCard from '../../components/ModalSolucoesCard';

import solucoesList from '../../data/solucoes.json';

import './solucoes.css';

const Solucoes = () => {

  const gestao_processos = [
    { icone: <IconImage size={80} url={diagnostico}/>, titulo: 'Diagnóstico financeiro' },
    { icone: <IconImage size={80} url={gestão_fina} />, titulo: 'Gestão Financeira' },
    { icone: <IconImage size={80} url={orcamentaria} />, titulo: 'Gestão Orçamentária' },
  ];

  const gestão_economica = [
    { icone: <IconImage size={80} url={processos_diagnosticos}/>, titulo: 'Diagnóstico de processos financeiros' },
    { icone: <IconImage size={80} url={processos_dados} />, titulo: 'Mapeamento de processos - Setor Financeiro' },
    { icone: <IconImage size={80} url={implantacao}/>, titulo: 'Implantação do Setor Financeiro' },
  ]

    return (
         <div className="full-page-section-solucoes">
          
          <div className='cards-global'>

          <div style={{
            width:'100%', 
            alignItems:'center', 
            justifyContent:'center', 
            textAlign:'center',
            marginBottom:'30px'}}>
            <h2 className='titulo-solucoes'>
              Serviços e Soluções
            </h2>
          </div>

            <Container>
              <h4 className='title-section'>Gestão Econômica Financeira</h4>
              <hr/>
                <Row className="justify-content-center">
                  {gestao_processos.map((cartao, index) => (
                    <Col key={index} sm={4} className="mx-auto mb-4">
                      <Card className="card-animada">
                        <Card.Body className="text-center card-animada-body">
                          <div className="card-icone-titulo">
                            <div className="card-icone">{cartao.icone}</div>
                            <div className="card-titulo">{cartao.titulo}</div>
                            <ModalCard card={solucoesList[cartao.titulo]}/>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
            </Container>
            
            <Container>
              <h4 className='title-section'>Gestão de Processos Financeiros</h4>
              <hr/>
                <Row className="justify-content-center">
                  {gestão_economica.map((cartao, index) => (
                    <Col key={index} sm={4} className="mx-auto mb-4">
                      <Card className="card-animada">
                        <Card.Body className="text-center card-animada-body" >
                          <div className="card-icone-titulo">
                            <div className="card-icone">{cartao.icone}</div>
                            <div className="card-titulo">{cartao.titulo}</div>
                            <ModalCard card={solucoesList[cartao.titulo]}/>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
            </Container>
          </div>
     </div>
    );
}

export default Solucoes;