import CarouselHome from '../../components/CarouselHome';
import './home.css';

const Home = () => {

    return (
        <CarouselHome/> 
    );
}

export default Home;