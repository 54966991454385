import React from 'react';
import {Carousel, Row , Col, Image}from 'react-bootstrap';

import ImgSlide3 from '../../assets/back-slade2.jpeg';
import Background1 from '../../assets/background1.png';
import LogoWhite from '../../assets/logo-white.png';
import MonitorSlide from '../../assets/monitor-slide.png';

import { FaCheck } from 'react-icons/fa';

import "./style.css";

import content from '../../data/home-slides.json';

function CarouselHome() {

  const content_problemas = content["problemas"];
  const content_dicas = content["dicas"];
  const content_sobre_gestao = content["sobre-gestao"];

  return (
    <>
        <Carousel variant="dark" indicators={true} controls={false}>

            <Carousel.Item  interval={5000} style={{height:'90vh'}}>
                    <Row  style={{position:'relative'}}>
                        <img src={Background1} className='bg-image-slide1' alt="Second slide 1"/>
                        <Col md={12} className="d-flex align-items-center justify-content-center">
                            <div className="d-flex justify-content-center align-items-center" style={{height:'90vh'}}>
                                <Image src={LogoWhite} alt="LogoWhite"/>
                            </div>
                        </Col>
                    </Row>
            </Carousel.Item>

            <Carousel.Item interval={5000} style={{height:'90vh'}}>
                    <Row>
                        <Col md={6} className="col-slid2-img">
                            <div style={{padding:'0px 0px 20px 10px'}}>
                                <h1 style={{fontWeight:'bold', color:'#fff', marginBottom:'50px', marginLeft:'20px'}}>Por que  falar sobre<br/><strong style={{color:'#ffc600'}}>gestão financeira?</strong></h1>
                                <pre className='slide2-descricao'>
                                    {content_sobre_gestao}
                                </pre>
                            </div>
                        </Col>
                        <Col md={6} style={{backgroundColor:'#ffc600', paddingTop:'5%', height:'90vh'}}>
                        <div className="jump-animation">
                            <div className="slide-animation">
                                <div className="d-flex justify-content-center align-items-center">
                                    <Image height={400} src={MonitorSlide} alt="MonitorSlide" />
                                </div>
                            </div>
                        </div>
                        </Col>
                    </Row>
            </Carousel.Item>

            <Carousel.Item  interval={5000} style={{height:'90vh'}}>

                    <Row  style={{position:'relative'}}>

                        <img src={ImgSlide3} className='bg-image-slide3' alt="Second slide 3"/>

                        <Col md={4} className="d-flex align-items-center justify-content-center"
                        style={{paddingLeft:'3%', paddingTop:'25%'}}>
                            <div>
                                <h1 className="title-slide3">Sua Empresa passa por algum desses problemas?</h1>
                            </div>
                        </Col>
                        <Col md={8} className='col-slide3' style={{paddingTop:'10%'}}>
                            {
                                content_problemas.map((text, index) => (
                                    <div key={index} className="text-box-home">
                                        <FaCheck className="icon" size='20px'/>
                                        <p className="text-home">{text}</p>
                                    </div>
                                ))
                            }
                        </Col>
                    </Row>
            </Carousel.Item>

            <Carousel.Item interval={5000} style={{height:'90vh'}}>
                    <Row>
                        <Col md={6} className="col-slid4-img">
                            <div style={{padding:'0px 0px 20px 10px'}}>
                                <h1 style={{fontWeight:'bold', color:'white'}}>6 Dicas para<br/> melhorar a <strong style={{color:'#ffc600'}}>Saúde<br/> Financeira</strong> da Sua<br/> empresa</h1>
                            </div>
                        </Col>
                        <Col md={6} style={{backgroundColor:'#0d1226', paddingTop:'5%', height:'90vh'}}>
                            {
                                content_dicas.map((value, index) => (
                                    <div key={index} className="text-box-home-dicas">
                                        <div className="dicas-img-container">
                                            <img src={value.image} alt="Icon" className="icon-img-slide-dicas" />
                                        </div>
                                        <p style={{color:'white'}}>{value.description}</p>
                                    </div>
                                ))
                            }
                        </Col>
                    </Row>
            </Carousel.Item>
        </Carousel>
    </>
  );
}

export default CarouselHome;
